import useRazorpay from 'react-razorpay';
import { useCallback, useEffect, useRef, useState } from 'react';

// copilot make an http post request using fetch
const makeRequest = async (url, data) => {
  const response = await fetch(url, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(data),
  });

  const contentType = response.headers.get('content-type');

  if (!contentType || !contentType.includes('application/json')) {
    throw new TypeError("Oops, we haven't got JSON!");
  }

  return response.json();
};

export default function App() {
  const btnRef = useRef(null);
  const Razorpay = useRazorpay();
  const [err, setErr] = useState('');
  const [success, setSuccess] = useState('');

  const handlePayment = useCallback(() => {
    const options = {
      key: 'rzp_live_sBVFluBrJZ404K',
      amount: '119900',
      currency: 'INR',
      name: 'Level',
      description: 'Offers',
      image: 'https://i.ibb.co/gv0L4bt/level.png',
      order_id: '',
      handler: (res) => {
        if (res.razorpay_payment_id) {
          setErr(() => '');

          makeRequest(
            'https://level-monolith.api.level.game/app/website/payment',
            { transaction_id: res.razorpay_payment_id }
          )
            .then((data) => {
              setSuccess(
                'Payment Successful. ' +
                  `Your subscription is extended upto ${data.message}.`
              );
            })
            .catch(() => {
              setErr('Payment Failed. Please contact us at hey@level.game');
            });
        } else {
          setSuccess(() => '');
          setErr('Payment Failed. Please contact us at hey@level.game');
        }
      },
      prefill: {
        name: '',
        email: '',
        contact: '',
      },
      notes: {
        address: 'Razorpay Corporate Office',
      },
      theme: {
        color: '#3399cc',
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.open();
  }, [Razorpay]);

  useEffect(() => {
    const timer = setTimeout(() => {
      btnRef.current.click();
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className="App"
      style={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <form
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          maxWidth: '25rem',
          width: '100%',
          margin: 'auto',
          gap: '1rem',
          padding: '3rem',
        }}
        onSubmit={(ev) => {
          ev.preventDefault();
          handlePayment();
        }}
      >
        <button
          ref={btnRef}
          type="submit"
          onClick={handlePayment}
          style={{ padding: '0.4rem 1.5rem', font: 'inherit', display: 'none' }}
        >
          Pay Now
        </button>

        <label style={{ color: 'red' }}>{err}</label>
        <label style={{ color: 'green' }}>{success}</label>
      </form>
    </div>
  );
}
